<script>
  import logo from '../images/aid.svg?raw';
  import logoCircle from '../images/aid-circle.svg?raw';
  import InlineSVG from "~/components/InlineSVG.svelte";

  export let variant = 'default'
</script>

<div>
  {#if variant === 'circle'}
    <InlineSVG svg={logoCircle} />
  {:else}
    <InlineSVG svg={logo} />
  {/if}

</div>

<style>
    @import '../common/colors.css';
    div {
        width: 100%;
    }
    div:global(.aid-logo--a) {
        fill: var(--pink);
    }
    div:global(.aid-logo--id) {
        fill:var(--black);
    }
</style>
